.form-floating > label {
    left: 3px;
    font-size: 90%;
}

fieldset{
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    padding: 1em;
}

//.label-floating{
//
//}
//.label-on-top{
//    font-size: 85%;
//    display: none;
//}
//@media (min-width: 1400px){
//    .label-on-top{
//        display: inline-block;
//    }
//    .label-floating{
//        display: none;
//    }
//
//}
