// Fonts
@import url('https://fonts.googleapis.com/css?family=Inter');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

@import "~toastr";

@import '../../node_modules/vanillajs-datepicker/sass/datepicker-bs5';

@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/regular";

@import "~dropzone/dist/dropzone.css";

@import "~select2";
@import "~select2-bootstrap-5-theme";
@import "~fullcalendar/dist/fullcalendar.css";
@import "~@fullcalendar/common/main.css";
@import "~@fullcalendar/bootstrap5/main.css";

@import "adminkit/app";

@import 'forms';

@import 'buttons';
@import 'datatables';
@import "apartment";
@import "calendar";
@import "users";

.interview-group {
    background: #efefef;
    padding: 20px;
    border-radius: 10px;
}
