.no-padding {
    padding: 0;
}
.modal .modal-header {
    -webkit-border-top-left-radius: .2rem;
    -webkit-border-top-right-radius: .2rem;
    -moz-border-radius-topleft: .2rem;
    -moz-border-radius-topright: .2rem;
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
}
.modal-success .modal-header {
    color: #ffffff;
    background-color: #5cb85c;
}
.modal-warning .modal-header {
    color:#fff;
    background-color: #f0ad4e;
}
.modal-danger .modal-header {
    color:#fff;
    background-color: #d9534f;
}
.modal-info .modal-header {
    color:#fff;
    background-color: #5bc0de;
}
.modal-primary .modal-header {
    color:#fff;
    background-color: #428bca;
}

.users-table {
    border: 0;
}
.users-table tr td:first-child {
    padding-left: 15px;
}
.users-table tr td:last-child {
    padding-right: 15px;
}
.users-table.table-responsive,
.users-table.table-responsive table {
    margin-bottom: 0;
}
.clickable-row:hover {
    cursor: pointer;
}
.users-table.table-responsive {
    border: none;
}
.users-table .list-group {
    margin-bottom: 0;
}
.users-table .dropdown-menu > li button {
    padding: 3px 20px;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    text-align: left;
    white-space: nowrap;
}
.users-table .dropdown-menu > li button:hover {
    background: rgba(0,0,0,.04);
}

.user-table {
    border: 0;
}
.user-table tr th {
    border: 0 !important;
}
.user-table tr th:first-child,
.user-table tr td:first-child {
    padding-left: 15px;
}
.user-table tr th:last-child,
.user-table tr td:last-child {
    padding-right: 15px;
}
.user-table .table-responsive,
.user-table .table-responsive table {
    margin-bottom: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.btn-save,
.pw-change-container {
    display: block;
}
.has-error,
.has-error input,
.has-error select,
.has-error textarea {
    border-color: #d9534f;
}
.clear-search {
    display: none;
}
.badge {
    display: inline-block;
    padding: 0.3em 0.45em;
    font-size: 80%;
    font-weight: 600;
    line-height: 1;
    color: $blue;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.2rem;
}
